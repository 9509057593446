import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import parse from 'html-react-parser';

import Modal from 'components/UI/Modal/ModalV2';
import { md } from 'utils/breakpoints';
import IconClose from 'images/close-3.svg';

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div``;

const CloseBtn = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Content = styled.div`
  height: 500px;
  overflow-y: scroll;

  @media (max-width: ${md}) {
    height: 466px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalCouponRule = (props) => {
  const { isOpen, onCancel, content = '' } = props;

  return (
    <Modal
      width={isMobile ? '90%' : '520px'}
      isOpen={isOpen}
      title={
        <TitleWrapper>
          <Title></Title>
          <CloseBtn onClick={onCancel}>
            <img src={IconClose} />
          </CloseBtn>
        </TitleWrapper>
      }
      showCancelBtn={false}
      showOkBtn={false}
    >
      <Content>{parse(content)}</Content>
    </Modal>
  );
};

export default ModalCouponRule;
