import React, { useEffect, useState } from 'react';
import { getters } from 'redux/store';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Footer from 'components/UI/Footer';
import Breadcrumb from 'components/UI/Breadcrumb';
import CouponCenter from 'components/UI/Card/CouponCenter';
import ModalCouponRule from './ModalCouponRule';
import { useAPI } from 'utils/api';
import Skeleton from 'react-loading-skeleton';
import CcAuth from 'components/CcAuth';

import { md } from 'utils/breakpoints';

const mixinRefundList = css`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  @media (max-width: ${md}) {
    gap: 24px 0;
  }
`;

const mixinRefundItem = css`
  flex-basis: calc((100% - 12px) / 2);
  border-radius: 8px;
  min-height: 127px;

  @media (max-width: ${md}) {
    flex-basis: 100%;
  }
`;

const Container = styled.div`
  width: 1080px;
  padding-top: ${(props) => `${props.headerHeight}px`};
  margin: 0 auto;
  .skeleton-refund-list {
    ${mixinRefundList};

    .skeleton-refund {
      ${mixinRefundItem};
    }
  }

  @media (max-width: ${md}) {
    width: 100%;
    background-color: #fff;
  }
`;

const StyledHeader = styled(Header)`
  .switch-delivery-wrapper,
  .address-wrapper,
  .input-search-wrapper,
  .btn-cart-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }

  .usage-btn {
    display: none;

    @media (max-width: ${md}) {
      display: flex;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${md}) {
    gap: 0;
    padding: 8px 16px;
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
`;

const TitleBarBtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const UsageBtn = styled.div`
  width: 114px;
  height: 38px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 30px;

  @media (max-width: ${md}) {
    display: flex;
    order: 3;
    margin-left: auto;
    width: 88px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
      border-radius: 30px;
    `}
`;

const CouponListWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100% - 85px);
  padding: 2px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CouponList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;

  @media (max-width: ${md}) {
    gap: 10px;
  }

  > div {
    flex: 0 0 calc((100% - 12px) / 2);

    @media (max-width: ${md}) {
      flex: 0 0 100%;
    }
  }
`;

const PageCoupon = (props) => {
  const pageTitle = '折價券中心';
  const couponTicketList = useSelector((state) => getters.getCouponList(state));
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showModalUsage, setShowModalUsage] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [couponRule, setCouponRule] = useState('');
  const [loading, setLoading] = useState(false);
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const api = useAPI();

  const closeModalUsage = () => {
    setShowModalUsage(false);
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;
    setLoading(true);
    api
      .getCouponList()
      .then((res) => {
        setLoading(false);
        setCouponList(res);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    api
      .getCouponRule()
      .then((res) => {
        if (!res) {
          return;
        }
        setCouponRule(res.content);
      })
      .catch((error) => {});
  }, [ccAuthIsReady]);

  return (
    <>
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <Container headerHeight={headerHeight}>
          <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
            <StyledHeader
              title="折價券中心"
              extraBtn={
                <UsageBtn
                  className="usage-btn"
                  onClick={() => setShowModalUsage(true)}
                >
                  使用辦法
                </UsageBtn>
              }
            />
          </HeaderWrapper>

          <Breadcrumb
            items={[
              { title: <Link to="/">首頁</Link> },
              { title: <Link to="/coupon">{pageTitle}</Link> },
            ]}
          />

          <ContentWrapper>
            <TitleBar>
              <Title>{pageTitle}</Title>
              <TitleBarBtnGroup>
                <UsageBtn onClick={() => setShowModalUsage(true)}>
                  使用辦法
                </UsageBtn>
              </TitleBarBtnGroup>
            </TitleBar>

            {loading ? (
              <Skeleton
                containerClassName="skeleton-refund-list"
                className="skeleton-refund"
                count={10}
                inline
              />
            ) : (
              <CouponListWrapper>
                <CouponList>
                  {couponList.map((coupon, idx) => {
                    return <CouponCenter key={idx} data={coupon} />;
                  })}
                </CouponList>
              </CouponListWrapper>
            )}
          </ContentWrapper>

          <Footer />
        </Container>
      )}

      <ModalCouponRule
        isOpen={showModalUsage}
        onCancel={closeModalUsage}
        content={couponRule}
      ></ModalCouponRule>
    </>
  );
};

export default PageCoupon;
